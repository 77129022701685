<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  metaInfo: {
    title: 'EuGMS 2024'
  },
  data () {
    return {
      deviceId: null,
      customHistory: JSON.parse(sessionStorage.getItem('customHistory') || '[]')
    }
  },
  watch: {
    $route (to, from) {
      this.windowParent.postMessage({ type: 'routeChange', data: to.path }, '*')

      if (!window.isNavigatingBack) {
        // Aggiungi la rotta precedente solo se non stiamo navigando indietro
        if (from.fullPath) {
          this.customHistory.push(from.fullPath);

          // Limita la cronologia a 20 elementi
          if (this.customHistory.length > 20) {
            this.customHistory.shift(); // Rimuovi il primo elemento (il più vecchio)
          }

          sessionStorage.setItem('customHistory', JSON.stringify(this.customHistory));
        }
      } else {
        // Reset del flag dopo la navigazione indietro
        window.isNavigatingBack = false;
      }
    },
    deviceId: {
      handler (val) {
        if (val) {
          this.loginByDevice({ pushDeviceId: val })
        }
      }
    }
  },
  mounted () {
    this.thisWindow.addEventListener('deviceIdStored', (event) => {
      this.deviceId = event.detail.deviceId
    })
  },
  methods: {
    ...mapActions({
      loginByDevice: 'auth/loginByDevice'
    })
  }
}
</script>
